/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.centerContainer {
  margin-left: auto;
  margin-right: auto;
  width:1250px;
}
.defaultPoll {
  color: #ffffff
}
.dangerPoll {
  color: #d32f2f
}
.warningPoll {
  color: #f57c00
}

.paginationcenter {
  position: absolute;
  padding-top: 20px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.pagination {
  display: flex;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #424242;
  color: #fff;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  pointer-events: none;
  cursor: not-allowed;
  text-decoration: none;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  pointer-events: none;
  cursor: not-allowed;
  text-decoration: none;
  color: rgb(198, 197, 202);
  border: 1px solid #303030;
}

#app {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header' 'main' 'footer';
}
#app > header {
  /*padding: 10px 5px;*/
  /*height: 50px;*/
 /* position: relative;*/
  /*padding-top: 18px;*/
  /*background-color: lightblue;*/
}
#app footer {
  /*padding: 10px 5px;*/
  height: 65px;
  padding-top: 2px;
  position: relative;
  background-color: #212121;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  /*padding-top: 18px;*/
  /*background-color: lightblue;*/
}
#app > header {
  grid-area: header;
}
#app > header a {
  margin-right: 20px;
  cursor: pointer;
}
#app > header a.disabled {
  cursor: default;
  opacity: 0.5;
}
#app > main {
  grid-area: main;
  overflow: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr minmax(0, 600px) 1fr;
}
#app > main > section {
  grid-area: 1 / 2 / 1 / 3;
  padding: 5px 0 0 0;
  width: 100%;
  display: flex;
}
#app > main > section > * {
  overflow: auto;
}
#app > main > section.full {
  grid-area: 1 / 1 / 1 / 4;
}
#app > main > footer {
  height: 50px;
  grid-area: 2 / 1 / 2 / 4;
}
/*
.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clear {
  clear: both;
}
