.wrapper {
  display: flex;
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
}

.wrapper > * {
  padding: 0;
  flex: 1 0 80%;
}
.middleSection {
  flex-direction: column;
}
.middleSectionBottom {
  flex-flow: row wrap;
  flex-direction: row;
}
.middleSectionBottom div {
  display: inline-flex;
  width: 100%;
}
.middleSectionBottom div.extrafield {
    width: calc(50% - 5px);
}
.middleSection label {
  margin-bottom: 0;
}
.field2 {
    margin-right: 10px;
}

@media all and (min-width: 600px) {
  .aside {
    flex: 1 0 0;
  }
}

@media all and (min-width: 800px) {
  .main {
    flex: 10 0px;
  }
  .main {
    order: 2;
  }
  .field2 {
    order: 3;
  }
  .field3 {
    order: 4;
  }
  .aside-2 {
    order: 5;
  }
}
