.displayedTime {
  margin-bottom: 0;
  color: #6fb700;
  font-weight: 800;
}

.displayedTime h1 {
  font-weight: 900;
}

.displayedTime.warning {
  color: red;
}

.countdown input {
  display: inline-block;
  margin-right: 10px;
}

.countdown .buttons {
  display: inline-block;
  margin-bottom: 10px;
}

.countdown button {
  display: inline-block;
}
