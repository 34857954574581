@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: normal;
  src: url("fonts/TelstraAkkuratWeb-Light.eot");
  src: url("fonts/TelstraAkkuratWeb-Light.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-Light.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-Light.woff") format("woff"), url("fonts/TelstraAkkuratWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: italic;
  src: url("fonts/TelstraAkkuratWeb-LightItalic.eot");
  src: url("fonts/TelstraAkkuratWeb-LightItalic.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-LightItalic.woff") format("woff"), url("fonts/TelstraAkkuratWeb-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: normal;
  src: url("fonts/TelstraAkkuratWeb-Regular.eot");
  src: url("fonts/TelstraAkkuratWeb-Regular.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-Regular.woff") format("woff"), url("fonts/TelstraAkkuratWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: italic;
  src: url("fonts/TelstraAkkuratWeb-Italic.eot");
  src: url("fonts/TelstraAkkuratWeb-Italic.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-Italic.woff") format("woff"), url("fonts/TelstraAkkuratWeb-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Akkurat";
  font-weight: bold;
  font-style: normal;
  src: url("fonts/TelstraAkkuratWeb-Bold.eot");
  src: url("fonts/TelstraAkkuratWeb-Bold.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-Bold.woff") format("woff"), url("fonts/TelstraAkkuratWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Akkurat";
  font-weight: bold;
  font-style: italic;
  src: url("fonts/TelstraAkkuratWeb-BoldItalic.eot");
  src: url("fonts/TelstraAkkuratWeb-BoldItalic.svg#Akkurat") format("svg"), url("fonts/TelstraAkkuratWeb-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkuratWeb-BoldItalic.woff") format("woff"), url("fonts/TelstraAkkuratWeb-BoldItalic.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Akkurat, Arial, Helvectica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  height: 100%;
}
