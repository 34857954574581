body {
    background-color: #ffffff;
    color: #000000;
    font-family: Akkurat, Arial, Helvectica, sans-serif;
    padding: 0;
    margin: 0;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.textfill {
    font-family: Akkurat, Arial, Helvectica, sans-serif;
    color: #000000;
    position: absolute;
    text-align: left;
    line-height: 1.25;
    letter-spacing: normal;
}
